import React from 'react';
import LogoIcon from '../shared/svg/LogoIcon';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import classes from './index.module.scss';

interface LogoCopyRightProps {
  className?: string;
  copyRightLabel?: string;
  logoGray?: boolean;
}

const LogoCopyRight: React.FC<LogoCopyRightProps> = ({
  className,
  copyRightLabel = new Date().getFullYear(),
  logoGray,
}) => {
  return (
    <Flex className={cnj(classes.LogoCopyRightRoot, className)}>
      <LogoIcon visiblelobox className={classes.logoImage} gray={logoGray} />
      <Flex as="span" className={classes.copyRightText}>
        {copyRightLabel}
      </Flex>
    </Flex>
  );
};

export default LogoCopyRight;
