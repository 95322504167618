import classes from './LanguageDropDown.module.scss';

import React from 'react';
import { siteLanguagesEnum } from '@lobox/utils';
import cnj from '../utils/cnj';
import DropdownSelect from '@lobox/uikit/AutoComplete/DropdownSelect';
import ListItem from '../ListItem';

export interface StyleProps {
  dropDown?: string;
  options?: string;
}

export interface LanguageDropDownProps {
  onChange: (value: any) => void;
  currentLanguage: keyof typeof siteLanguagesEnum;
  styles?: Partial<StyleProps>;
  label?: string;
}

const LanguageDropDown: React.FC<LanguageDropDownProps> = ({
  onChange,
  currentLanguage,
  styles,
  label,
}) => {
  const value = siteLanguagesEnum[currentLanguage];

  return (
    <DropdownSelect
      label={label}
      className={cnj(classes.dropDown, styles?.dropDown)}
      optionItemClassName={classes.optionItem}
      onChange={onChange}
      value={value}
      options={Object.values(siteLanguagesEnum)}
      rightIconClassName={classes.rightIcon}
      renderItem={({ isSelected, item }) => (
        <ListItem
          className={cnj(
            classes.listItem,
            isSelected && classes.selectedLanguage
          )}
          labelClassName={cnj(isSelected && classes.selectedLanguage)}
          label={item?.label}
        />
      )}
      styles={{ options: cnj(classes.options, styles?.options) }}
    />
  );
};

export default LanguageDropDown;
